import React, { useEffect, useState } from "react";
import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Header from "../component/Header";
import Testimonal from "../component/Testimonal";
import StudyGermany from "../component/StudyGermany";
import OurPartners from "../component/OurPartner";
import SyncDocument from "../component/SyncDocument";
import StayinTouch from "../component/StayinTouch";
import Transparency from "../component/Transparency";
import News from "../component/News";
import Footer from "../component/Footer";
import Universitycomp from "../component/Universitycomp";
import PopUp from "../component/Modal/PopUp";
import Usp from "../component/Usp";
import Reviews from "../component/Reviews";
import Mentor from "../component/Mentor";
import Popupform from "../component/Modal/Popupform";

const LandingPage = () => {
  const [watchStory, setWatchstory] = useState(false);
  const [localUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  console.log(localUser, "localdata");

  const handleWatchStory = () => {
    setTimeout(() => {
      setWatchstory(true);
    }, 6000);
  };

  useEffect(() => {
    if (localUser?._id) {
      setWatchstory(false);
    } else {
      handleWatchStory();
    }
  }, [localUser]);

  const handleForm = () => {
    setWatchstory(!watchStory);
  };

  return (
    <>
      <div>
        <Header />
        <Navbar />
        <Hero />
        <Testimonal />
        <Usp />
        <StudyGermany />
        <Transparency />
        <Reviews />
        <OurPartners />
        <Mentor />

        <Universitycomp />
        <SyncDocument />
        <StayinTouch />
        <News />
        <Footer />
        {/* <div className="fixed bottom-11 right-10">
          <Link
            to="https://wa.me/8452850812"
            class="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={whatsapp}
              alt="whatsapp"
              height={35}
              width={35}
              style={{ borderRadius: "3px" }}
            />
          </Link>
        </div> */}
        {watchStory && <Popupform handleForm={handleForm} />}
      </div>
    </>
  );
};

export default LandingPage;
