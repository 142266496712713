import React, { useEffect } from "react";
import "../styles/OurPartner.css";
import axis from "../assets/partner/axis.png";
import commerz from "../assets/partner/commerz.png";
import hdfc from "../assets/partner/hdfc.webp";
import bajaj from "../assets/partner/bajajnew.png";
import flywire from "../assets/partner/flywire.png";
import remitout from "../assets/partner/Group 1139.svg";

import AOS from "aos";
import "aos/dist/aos.css";
const OurPartners = () => {
  // useEffect

  useEffect(() => {
    AOS.init({
      duration: 1800,
      offset: 50,
    });
  });
  return (
    <>
      <section className=" partnerWrapper ps-5  px-6 py-4 sm:ps-10 sm:py-8 lg:ps-36 lg:py-16 ">
        <div className="text-center sm:text-left container">
          <div className="mb-16 xl:mb-24">
            <h4
              className="mb-2 text-xl sm:text-3xl font-semibold text-center sm:text-left xl:mb-4"
              style={{ fontFamily: "Gilroy" }}
            >
              Our Partners
            </h4>
            <p
              className="text-lg   sm:text-md w-full sm:h-2/3 lg:w-1/2"
              style={{ fontFamily: "Gilroy-Medium" }}
            >
              We have collaborate with a diverse array of partners,
              each bringing unique expertise and value to make journey
              of our student hassle free
            </p>
          </div>
          <div className="flex flex-wrap items-center gap-4 sm:gap-12 lg:gap-14 justify-between sm:justify-normal">
            <div>
              <img
                src={axis}
                alt="img"
                className="flex-shrink-0 w-24 object-contain m-auto sm:m-0 xl:mt-2 "
              />
            </div>
            <div>
              <img
                src={flywire}
                alt="img"
                className="flex-shrink-0 w-32 object-contain m-auto sm:m-0 xl:mt-2"
              />
            </div>

            <div>
              {" "}
              <img
                src={remitout}
                alt="img"
                className="flex-shrink-0 w-32 object-contain m-auto sm:m-0 xl:mt-2"
              />
            </div>
            <div>
              <img
                src={hdfc}
                alt="img"
                className="flex-shrink-0 w-28 object-contain m-auto sm:m-0 xl:mt-2"
              />
            </div>
            <div>
              <img
                src={commerz}
                alt="img"
                className="flex-shrink-0 w-24 object-contain m-auto sm:m-0 xl:mt-2"
              />
            </div>
            <div>
              <img
                src={bajaj}
                alt="img"
                className="flex-shrink-0 w-36 sm:w-56 object-contain m-auto sm:m-0 xl:mt-2"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurPartners;
