import React, { useEffect } from "react";

const WhatsAppWidget = () => {
  useEffect(() => {
    const url =
      "https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?34691";
    const options = {
      enabled: true,
      chatButtonSetting: {
        backgroundColor: "#25d366",
        ctaText: "Chat with us",
        borderRadius: "25",
        marginLeft: "0",
        marginRight: "20",
        marginBottom: "20",
        ctaIconWATI: false,
        position: "right",
      },
      brandSetting: {
        brandName: "Germanywale",
        brandSubTitle: "untitled",
        brandImg:
          "https://www.wati.io/wp-content/uploads/2023/04/Wati-logo.svg",
        welcomeText: "Hi there!\nHow can I help you?",
        messageText:
          "Hello, %0A I have a question about {{page_link}}",
        backgroundColor: "#25D366",
        ctaText: "Chat with us",
        borderRadius: "25",
        autoShow: false,
        phoneNumber: "8484977234",
      },
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = url;

    script.onload = function () {
      window.CreateWhatsappChatWidget(options);
    };

    document.body.appendChild(script);

    return () => {
      // Cleanup function if needed
    };
  }, []);

  return null; // This component doesn't render anything visible
};

export default WhatsAppWidget;
