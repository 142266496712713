import React from "react";
import Slider from "react-slick";

// profile image
import dhiraj from "../assets/mentor/dhiraj.png";
import himanshu from "../assets/mentor/himanshu.png";
import samy from "../assets/mentor/samy.png";
import laura from "../assets/mentor/laura.png";
import alicia from "../assets/mentor/alicia.png";
import safa from "../assets/mentor/safa.png";
import srihari from "../assets/mentor/srihari.png";
import vijay from "../assets/mentor/vijay.png";
import saurabh from "../assets/mentor/saurabh.png";
import luca from "../assets/mentor/luca.jpeg";

// study and work
import avl from "../assets/mentor/avl.svg";
import rwth from "../assets/mentor/rwth.svg";
import ohm from "../assets/mentor/ohm.svg";
import worldline from "../assets/mentor/worldline.svg";
import coracle from "../assets/mentor/coracle.svg";
import tuhh from "../assets/mentor/tuhh.svg";
import careerbe from "../assets/mentor/careerbe.svg";
import thd from "../assets/mentor/thd.svg";
import aliciawork from "../assets/mentor/alicia.svg";
import upl from "../assets/mentor/upl.svg";
import hhu from "../assets/mentor/hhu.svg";
import martinrea from "../assets/mentor/martinrea.svg";
import fh from "../assets/mentor/fh.svg";
import tenneco from "../assets/mentor/tenneco.svg";
import technische from "../assets/mentor/technische.svg";
import thyssen from "../assets/mentor/thyssen.svg";
import hochschule from "../assets/mentor/hochschule.svg";
import Mentornew from "./Mentornew";
import { NextArrow, PrevArrow } from "./slider/Customarrow";

const Mentor = () => {
  const mentorData = [
    {
      image: dhiraj,
      name: "Dheeraj Dey",
      designation: "Product Management",
      working: avl,
      studiedAt: rwth,
    },
    {
      image: saurabh,
      name: "Saurabh Sarkar",
      designation: "Business & Strategy Development ",
      working: thyssen,
      studiedAt: hochschule,
    },
    {
      image: himanshu,
      name: "Himanshu Chanana",
      designation: "Business Analyst at Worldline",
      working: worldline,
      studiedAt: ohm,
    },
    {
      image: samy,
      name: "Samy Thiyagarajan",
      designation: "Co-Founder & COO Coracle",
      working: coracle,
      studiedAt: tuhh,
    },
    {
      image: luca,
      name: "Luca Planert",
      designation: "Co-Founder & CEO careerbee.de",
      working: careerbe,
      studiedAt: thd,
    },
    {
      image: laura,
      name: "Laura Villafuerte",
      designation: "Co-Founder & CEO careerbee.de",
      working: careerbe,
      studiedAt: thd,
    },
    {
      image: alicia,
      name: "Alicia Aswani",
      designation: "Finance & Insurance Expert & Co-founder",
      working: aliciawork,
      studiedAt: tuhh,
    },
    {
      image: safa,
      name: "Safa'a Al-Adwan",
      designation: "Human Resources consultant ",
      working: upl,
      studiedAt: hhu,
    },
    {
      image: srihari,
      name: "Srihari Balasubramanian",
      designation: "Senior Project Engineer ",
      working: martinrea,
      studiedAt: fh,
    },
    {
      image: vijay,
      name: "Vijay Rana",
      designation: "Lead Application Engineer",
      working: tenneco,
      studiedAt: technische,
    },
  ];

  const settings = {
    arrows: true, // Hide default arrows
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />, // Use custom arrows
    prevArrow: <PrevArrow />, // Use custom arrows
    responsive: [
      {
        breakpoint: 1000, // Adjust the breakpoint value according to your design
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 608, // Adjust the breakpoint value according to your design
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="overflow-hidden px-4 pt-4 py-2 sm:py-6 sm:px-6 sm:pt-4 lg:px-8 lg:pt-3 h-max bg-[#f2f2f280]">
      <div className="container mentor-slider">
        <div className="mb-10">
          <h3
            className="text-center text-xl sm:text-4xl font-bold mt-4 mb-1 overflow-hidden bg-gradient-to-b from-[#E56D09] via-[#D63715] to-[#D63715] bg-clip-text text-transparent"
            style={{ fontFamily: "Gilroy" }}
          >
            Get Guided by 100+ Mentors
          </h3>
        </div>
        <div>
          <Slider {...settings}>
            {mentorData.map((val, ind) => {
              return (
                <div className="overflow-hidden">
                  <Mentornew val={val} ind={ind} />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Mentor;
