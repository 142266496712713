import React from "react";
import "../styles/Mentornewcss.css";

const Mentornew = ({ val }) => {
  return (
    <div className="wrapper m-auto sm:m-0 2xl:m-auto">
      <div className="box relative">
        <div className="box-header">
          <div className="bg-gradient-to-r from-[#ea7d06] via-[#d63815] to-[#d63815] py-14 w-full rounded-t-md"></div>
        </div>
        <div className="box-content">
          <h3 className="font-[gilroy] text-2xl my-2">{val.name}</h3>
          <p className="text-sm text-[gilroy-Medium] text-[#969696]">
            {val.designation}
          </p>
          <div className="mentor-details flex gap-2">
            <div className="text-[gilroy-Medium] text-[#969696] flex flex-col">
              Working at
              <img
                src={val.working}
                alt="work"
                className="mentor-logo"
              />
            </div>
            <div className="text-[gilroy-Medium] text-[#969696] flex flex-col">
              Studied at
              <img
                src={val.studiedAt}
                alt="work"
                className="mentor-logo"
              />
            </div>
          </div>
        </div>
        <div className="box-footer">
          <button className="px-6 py-2 border-2 border-[#d63815] rounded-2xl text-[#d63815] text-md">
            Learn More
          </button>
        </div>
        <div className="absolute top-16 left-1/2 translate-x-[-50%]">
          <img
            src={val.image}
            alt="imae"
            className="h-24 w-auto rounded-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Mentornew;
