import './App.css';
import PopUp from "./component/Modal/PopUp";
import Popupform from "./component/Modal/Popupform";
import WhatsAppWidget from "./component/WhatsAppWidget";
import LandingPage from "./page/LandingPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/contact" element={<Popupform />} />
        </Routes>
      </Router>
      <WhatsAppWidget />
    </div>
  );
}

export default App;
