import React from "react";
import homeImage from "../../assets/image/formImage.svg";
import data from "../../assets/data/by-code.json";
import { useForm } from "react-hook-form";
import axios from "axios";

const Popupform = ({ handleForm }) => {
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm();

  const addData = async (data) => {
    try {
      const response = await axios.post(
        "https://germany-backend-mpu3.vercel.app/auth/create",
        data,
        {
          headers: {
            Origin: "https://www.germanywale.in",
          },
        }
      );
      console.log(response);
      if (response.data && response.data.user) {
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.user)
        );
        console.log("User data stored in local storage");
      } else {
        console.log("No user data in response");
      }
    } catch (error) {
      console.log("Error while adding data", error);
    }
  };

  const onSubmit = (degree) => {
    const formData = getValues();
    console.log(formData, "form ");
    addData({ ...formData, degree: degree });
    handleForm(false);
  };

  return (
    <div className="bg-[#F5F5F5] z-50 h-min-[120vh] sm:h-screen fixed top-0 sm:top-1/2 left-0 sm:left-1/2 sm:translate-x-[-50%] sm:translate-y-[-50%] w-full">
      <div className="flex justify-center items-center h-screen px-4 sm:px-32">
        <div className="flex h-3/4 w-full rounded-xl sm:rounded-3xl overflow-hidden">
          <div className="hidden sm:flex sm:w-[40%] h-full">
            <img
              src={homeImage}
              alt="Loading..."
              className="h-full w-full object-cover"
            />
          </div>
          <div className="w-full sm:w-[60%] h-full">
            <div className="w-full h-full">
              <div className="relative py-6 sm:py-8 xl:py-9 w-full bg-gradient-to-r from-[#ea7d06] via-[#ea7d06] to-[#d63815] text-white text-xl sm:text-5xl">
                <p style={{ fontFamily: "Gilroy" }}>Willkommen!</p>
              </div>
              <div className="bg-white h-[80%] flex items-center justify-center">
                <div className="flex items-center justify-center w-full">
                  <form className="h-full w-full px-4">
                    <div className="flex flex-col space-y-4 sm:space-y-1 ">
                      <div className="flex">
                        <input
                          type="text"
                          className="w-full border px-1 sm:px-3 py-1 sm:py-2 xl:py-2 rounded-xl mb-1 sm:mb-5"
                          placeholder="Name*"
                          {...register("name", {
                            required: true,
                          })}
                        />
                        {/* Display error message for name field */}
                        {errors.name && (
                          <p className="text-red-500">
                            Name is required
                          </p>
                        )}
                      </div>
                      <div className="w-full flex gap-3 justify-between">
                        <select
                          className="border w-20 py-1 sm:py-2 xl:py-2 rounded-xl mb-1 sm:mb-5 px-2"
                          {...register("country")}
                        >
                          {data.map((val, ind) => (
                            <option
                              key={ind}
                              value={val.code}
                              style={{
                                height: "30px",
                                width: "100px",
                              }}
                            >
                              {val.flag}
                            </option>
                          ))}
                        </select>
                        <input
                          type="number"
                          className="w-full border rounded-xl mb-1 sm:mb-5 px-1 sm:px-3"
                          placeholder="Mobile No*"
                          {...register("contact", {
                            required: true,
                            // pattern: /^[0-9]{11}$/,
                          })}
                        />
                      </div>
                      <div className="w-full">
                        <input
                          type="email"
                          className="w-full border px-1 sm:px-3 py-1 sm:py-2 xl:py-2 rounded-xl mb-1 sm:mb-5"
                          placeholder="Email*"
                          {...register("email", {
                            required: true,
                            pattern: /^\S+@\S+$/,
                          })}
                        />
                        {/* Display error message for email field */}
                        {errors.email && (
                          <p className="text-red-500">
                            Enter a valid email address
                          </p>
                        )}
                      </div>
                      <div className="w-full">
                        <p className="text-center text-sm sm:text-md mb-1 sm:mb-5">
                          Which degree do you want to pursue?
                        </p>
                        <div className="flex justify-center gap-1 sm:gap-4">
                          <button
                            className="border bg-[#D63715] border-red-500 rounded-xl px-3 sm:px-10 py-2 text-white text-sm"
                            onClick={handleSubmit(() =>
                              onSubmit("Masters")
                            )}
                          >
                            Masters
                          </button>
                          <button
                            className="border bg-[#EA7D06] border-orange-500 rounded-xl px-3 sm:px-10 py-2 text-white text-sm"
                            onClick={handleSubmit(() =>
                              onSubmit("Bachelors")
                            )}
                          >
                            Bachelors
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popupform;
