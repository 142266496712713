import React, { useEffect } from "react";
import Slick from "./Slick";
import google from "../assets/reviews/google.svg";
import google_star from "../assets/reviews/google_star.svg";

import AOS from "aos";
import "aos/dist/aos.css";

const Reviews = () => {
  // useEffect

  useEffect(() => {
    AOS.init({
      duration: 1800,
      offset: 50,
    });
  });
  return (
    <section className="px-4 pt-4 py-10 sm:px-6 sm:pt-8 lg:px-10 lg:pt-10 bg-[#FBFBFB]">
      <div>
        <div className="mb-2">
          <h3
            className="text-center text-2xl sm:text-4xl font-bold mt-4 mb-1 py-2  overflow-hidden bg-[#EA7D06] bg-clip-text text-transparent"
            style={{ fontFamily: "Gilroy-Medium" }}
          >
            Reviews from around the Globe
          </h3>
        </div>
        <div className="flex flex-wrap gap-6 py-8 justify-center">
          <div className="bg-[#FFFFFF] p-5 flex items-center gap-8 justify-between">
            <div>
              <img
                src={google}
                alt="trustpilot"
                className="h-10 sm:h-16 w-full mb-2"
              />
              <img
                src={google_star}
                alt="star"
                className="h-6 sm:h-10 w-full"
              />
            </div>
            <div className="text-5xl text-[#FBBC05] font-extrabold">
              4.7
            </div>
          </div>
        </div>
        <div>
          <Slick />
        </div>
      </div>
    </section>
  );
};

export default Reviews;
